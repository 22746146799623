// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   api:'http://inspectmite.net/api/',
//   apiSite:'http://inspectmite.net/',
//   oldSite:'http://twinhouse.net/',
//   doSpace:'https://twininspection-development.nyc3.digitaloceanspaces.com',
// };


	const parsedUrl = new URL(window.location.href);
	const baseUrl = parsedUrl.host;
  
  	var constArray = {
    	production: true,
    	api:'https://live.twininspections.com/api/',
    	apiSite:'https://live.twininspections.com/',
    	oldSite:'https://twininspections.com/',
    	doSpace:'https://twin-inspections-media.nyc3.digitaloceanspaces.com',
	appDashboard:'ISM'  	
	};

  	if(baseUrl == 'app.finley.inspectmite.com') {
    		constArray = {
      		production: true,
      		api:'https://app.finley.inspectmite.com/api/',
    		apiSite:'https://app.finley.inspectmite.com/',
    		oldSite:'https://finley.inspectmite.com/',
      		doSpace:'https://finley-media.nyc3.digitaloceanspaces.com',
		appDashboard:'Finley'    	
		};
  	}
        if(baseUrl == 'app.nahs.inspectmite.com') {
                constArray = {
                production: true,
                api:'https://app.nahs.inspectmite.com/api/',
                apiSite:'https://app.nahs.inspectmite.com/',
                oldSite:'https://nahs.inspectmite.com/',
                doSpace:'https://napm-media.nyc3.digitaloceanspaces.com',
                appDashboard:'NAHS'
                };
        }
	if(baseUrl == 'app.napm.inspectmite.com') {
        	constArray = {
                production: true,
                api:'https://app.napm.inspectmite.com/api/',
                apiSite:'https://app.napm.inspectmite.com/',
                oldSite:'https://napm.inspectmite.com/',
                doSpace:'https://napm-media.nyc3.digitaloceanspaces.com',
        	appDashboard:'NAPM2'
		};
  	}
	if(baseUrl == 'app.ttsd.inspectmite.com') {
                constArray = {
                production: true,
                api:'https://app.ttsd.inspectmite.com/api/',
                apiSite:'https://app.ttsd.inspectmite.com/',
                oldSite:'https://ttsd.inspectmite.com/',
                doSpace:'https://ttsd-media.nyc3.digitaloceanspaces.com',
                appDashboard:'TTSD'
                };
        }
  	export const environment = constArray;


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
